<template>
  <div
    class="entity-list-header"
    :class="headerClasses"
  >
    <transition
      name="fade"
      mode="out-in"
    >
      <div
        v-if="isBulkOperationsMode"
        key="bulk-operations"
        class="entity-list-header__body entity-list-header__body--bulk-operations"
      >
        <bulk-operations-header
          :entity-list="entityList"
          :share-buttons="shareButtons"
          :is-mobile="isMobile"
        />
      </div>

      <div
        v-else
        key="default"
        class="entity-list-header__body"
      >
        <div class="entity-list-header__content">
          <entity-list-total-number
            ref="totalNum"
            class="entity-list-header__total-num"
            :entity-list="entityList"
            :is-loading-data="isLoadingData"
          />

          <component
            :is="headerBodyComponent"
            v-if="hasAnyItems"
            ref="headerBody"
            class="entity-list-header__operations"
            :share-buttons="shareButtons"
            :is-mobile="isMobile"
            :is-show-fields-settings="isShowFieldsSettings"
            :entity-list="entityList"
            :sort="sort"
            :custom-view="customViews"
            :list-views="listViews"
            :items="items"
            :fields="fields"
            @share="shareAction"
            @change-sort="$emit('change-sort', $event)"
            @change-view="$emit('change-view', $event)"
            @show-modal-settings="$emit('show-modal-settings')"
          >
            <template
              v-for="(_, name) in $scopedSlots"
              v-slot:[name]
            >
              <slot :name="name" />
            </template>
          </component>
        </div>
      </div>
    </transition>

    <modal-export-to-file
      v-if="hasShareButton('excel')"
      ref="exportToFileModal"
      :entity-list="entityList"
    />
  </div>
</template>

<script>
import TransitionExpand from '@/components/animation/TransitionExpand';
import EntityListTotalNumber from "@/components/entity-list/EntityListTotalNumber.vue";

const BulkOperationsHeader = () => import("@/components/entity-list/header/BulkOperationsHeader.vue");
const DefaultHeaderBody = () => import("@/components/entity-list/header/DefaultHeaderBody.vue");
const SupportHeaderBody = () => import("@/components/entity-list/header/SupportHeaderBody.vue");
const ModalExportToFile = () => import("@/components/entity-list/export-to-file/ModalExportToFile");

export default {
  name: 'entity-list-header',
  components: {
    BulkOperationsHeader,
    TransitionExpand,
    ModalExportToFile,
    DefaultHeaderBody,
    SupportHeaderBody,
    EntityListTotalNumber,
  },
  props: {
    customViews: { type: Array, default: Array },
    listViews: { type: Array, default: Array },
    items: { type: Array, default: Array },
    fields: { type: Array, default: Array },
    isMobile: { type: Boolean, default: false },
    sort: { type: Object, default: Object },
    entityList: { type: Object, default: Object },
    currentView: { type: Object, default: Object },
    isLoadingData: { type: Boolean, default: false },
    isShowFieldsSettings: { type: Boolean, default: false },
    headerType: { type: String, default: '' },
    hasAnyItems: { type: Boolean, default: false },
  },
  data() {
    return {
      headerBodyComponent: this.headerType === 'support' ? SupportHeaderBody : DefaultHeaderBody,
    };
  },
  computed: {
    shareButtons() {
      if (this.currentView.hideShareButtons) {
        return [];
      }

      const sharedOptions = this.entityList.config?.sharedOptions || {};
      const buttons = [];

      if (sharedOptions.print) {
        buttons.push({
          key: 'print',
          dataCy: 'print-button',
          icon: 'outline-print',
          text: this.$vDict('entity_list.print_button.text'),
          isLoading: this.entityList.isAction('print'),
        });
      }

      if (sharedOptions.exportToFile) {
        buttons.push({
          key: 'excel',
          dataCy: 'export-excel-button',
          icon: 'baseline-exce',
          text: this.$vDict('entity_list.export_to_excel_button.text'),
          isLoading: this.entityList.isAction('excel'),
        });
      }

      return buttons.map(button => ({
        ...button,
        disabled: button.isLoading || !this.items.length,
      }));
    },
    isBulkOperationsMode() {
      return this.entityList.config.hasBulkOperations &&
        this.entityList.selectedItems.length > 0;
    },
    headerClasses() {
      return {
        // 'entity-list-header--bulk-operations': this.isBulkOperationsMode,
        // 'entity-list-header--support-header': this.headerType === 'support',
      };
    },
  },
  mounted() {
    // if (!this.isPagination) {
    //   document.addEventListener('click', this.clickMoreActionOutside);
    // }

    this.resizeObserver = new ResizeObserver(this.checkIsHeaderBodyWrapped);
    this.resizeObserver.observe(this.$el);
  },
  beforeDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  },
  updated() {
    this.checkIsHeaderBodyWrapped();
  },
  methods: {
    checkIsHeaderBodyWrapped() {
      const { totalNum, headerBody } = this.$refs;
      const headerBodyEl = headerBody?.$el;
      const totalNumEl = totalNum?.$el;

      if (!totalNumEl || !headerBodyEl) {
        return;
      }

      this.$nextTick(() => {
        const totalNumTop = totalNumEl.getBoundingClientRect().bottom;
        const headerBodyTop = headerBodyEl.getBoundingClientRect().top;
        // const isWrapped = headerBodyEl.classList.contains('wrapped');

        if (headerBodyTop > totalNumTop) {
          headerBodyEl.classList.add('wrapped');
        } else {
          headerBodyEl.classList.remove('wrapped');
        }
      });
    },
    hasShareButton(shareKey) {
      return this.shareButtons.some(el => el.key === shareKey);
    },
    shareAction(shareKey) {
      if (shareKey === 'excel') {
        this.$refs?.exportToFileModal.show({
          exportFunc: async (fileName = '', payload = {}) => {
            await this.entityList.exportToExcel({
              fileName,
              customFields: payload.customFields,
            });
          },
        });
        return;
      }

      this.entityList.doAction(shareKey);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_mixins.scss';

.entity-list-header {
  $block: &;

  //position: relative;
  z-index: 10;
  padding: 12px 0 0 0;

  .entity-list.is-main & {
    padding: 8px 8px 0 8px;
  }

  .entity-list.is-cards-view & {
    position: sticky;
    //top: $navbar-height;
    top: 0;
    background: var(--main-bg);
  }

  .entity-list.is-main.is-cards-view & {
    background-color: var(--main-bg);
    border: var(--tabs-main-border);
    box-shadow: var(--tabs-main-shadow);
    border-radius: var(--border-radius);
    margin-bottom: 10px;
    padding: 8px;
  }

  .entity-list.is-loading &__operations {
    pointer-events: none;
    opacity: .65;
  }

  &__body {
    min-height: 49px;
    padding: 4px 8px 12px 8px;
    border-bottom: 1px solid var(--entity-list-header-line-color);
    display: flex;
    width: 100%;

    &--bulk-operations {
      padding-top: 8px;
      padding-bottom: 8px;
      background-color: var(--secondary-bg);
    }

    .entity-list.is-main.is-cards-view & {
      padding: 8px;
      border-bottom: 0;
    }
  }

  &__content {
    margin-bottom: -10px;
    width: 100%;

    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
    }
  }

  &__operations {
    display: flex;
    margin-bottom: 10px;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    @include media-breakpoint-up(md) {
      //margin-bottom: 10px;

      &.wrapped {
        flex-grow: 1;
        min-width: 0;

        & > *:not(.cards-sort):first-child {
          flex-grow: 1;
        }
      }
    }
  }
}
</style>
