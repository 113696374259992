<template>
  <div
    :class="{ 'view-only': viewOnly, 'is-mobile': isMobile }"
    data-cy="checkboxes-related"
    class="checkboxes-related"
  >
    <ul class="checkboxes-related__columns">
      <li
        is="checkboxes-related-column"
        v-for="(column, i) in columns"
        :key="i"
        :class="column.class"
        :items="column.items"
        :active-item="activeItem"
        :value="value"
        :is-exclude="isExclude"
        :disabled="disabled"
        :view-only="viewOnly"
        :is-mobile="isMobile"
        :data-cy="`checkbox-column-${i + 1}`"
        class="checkboxes-related__column"
      />
    </ul>
  </div>
</template>

<script>
import MixinCheckMobile from "@/mixins/MixinCheckMobile";
import CheckboxesRelatedColumn from './CheckboxesRelatedColumn';
import { getIdsFromArray } from '@/shared/utils';

export default {
  name: 'checkboxes-related',
  components: {
    CheckboxesRelatedColumn,
  },
  mixins: [
    MixinCheckMobile,
  ],
  props: {
    idKey: { type: String, default: 'id' },
    nameKey: { type: String, default: 'name' },
    items: { type: Array, default: Array },
    value: { type: Array, default: Array },
    isExclude: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    viewOnly: { type: Boolean, default: false },
  },
  data() {
    return {
      maxDepth: 3,
      minDepth: 2,
      activeCol: 0,
      activeItem: null,
      localItems: [],
      itemsDepth: 0,
    };
  },
  computed: {
    strItems() {
      return JSON.stringify(this.items);
    },
    columns() {
      return [
        {
          items: this.localItems,
          activeItem: this.activeItem,
        },
        {
          items: this.activeItem?.items || [],
          class: {
            'active-col': this.activeItem && this.isMobile,
          },
        },
      ];
    },
  },
  watch: {
    isMobile() {
      this.initActiveItem();
    },
    strItems: {
      handler() {
        let depth = this.getItemsDepth(this.items);

        if (depth > this.maxDepth) {
          depth = this.maxDepth;
        } else if (depth < this.minDepth) {
          depth = this.minDepth;
        }

        this.itemsDepth = depth;
        this.localItems = this.getLocalItems(this.items, this.itemsDepth);

        this.checkMobile();
        this.initActiveItem();
      },
      immediate: true,
    },
  },
  provide() {
    return {
      setActiveItem: this.setActiveItem,
      changeCheckboxesValue: this.changeCheckboxesValue,
    };
  },
  methods: {
    initActiveItem() {
      if (this.isMobile) {
        this.activeItem = null;
      } else {
        this.activeItem = this.getFirstChild(this.localItems);
      }
    },
    getItemsDepth(items = []) {
      let level = 1;

      items.forEach(item => {
        const children = item.items || [];

        if (children.length > 0) {
          let depth = this.getItemsDepth(children) + 1;
          level = Math.max(depth, level);
        }
      });

      return level;
    },
    getLocalItems(items, depth) {
      if (depth === 0) {
        return [];
      }

      let localItems = items.map(item => {
        let children = item.items || [];

        if (children.length === 0) {
          children = [item];
        }

        return {
          ...item,
          _id: item[this.idKey],
          _name: item[this.nameKey],
          _childrenIds: children.map(el => el[this.idKey]),
          _leafChildrenIds: getIdsFromArray(children, {
            idKey: this.idKey,
          }),
          _isFolder: depth === this.maxDepth,
          items: this.getLocalItems(children, depth - 1),
        };
      });

      if (this.viewOnly) {
        localItems = localItems
          .map(item => ({
            ...item,
            _someChecked: item._leafChildrenIds.some(id => this.value.includes(id)),
          }))
          .sort((a, b) => {
            if (!a._someChecked && b._someChecked) {
              return 1;
            }
            if (a._someChecked && !b._someChecked) {
              return -1;
            }
            return 0;
          });
      } else {
        localItems.sort((a, b) => {
          if (a.disabled && !b.disabled) {
            return 1;
          }
          if (!a.disabled && b.disabled) {
            return -1;
          }
          return 0;
        });
      }

      return localItems;
    },
    getFirstChild(items = []) {
      const firstItem = items[0];
      return !firstItem?._isFolder ? firstItem : null;
    },
    setActiveItem(item) {
      this.activeItem = item;
    },
    changeCheckboxesValue(item, checkboxChecked) {
      const itemIds = item._leafChildrenIds.length > 0 ? item._leafChildrenIds : [item._id];
      let res = [];

      if (checkboxChecked && this.isExclude) {
        const vals = checkboxChecked < 0 ? itemIds.map(el => -el) : itemIds;

        res = this.value
          .filter(el => !vals.includes(el) && !vals.includes(-el))
          .concat(vals);
      } else if (checkboxChecked) {
        res = _.union(this.value, itemIds);
      } else {
        res = this.value.filter(val => {
          const itemId = typeof val === 'number' ? Math.abs(val) : val;
          return !itemIds.includes(itemId);
        });
      }

      this.$emit('input', res);
      this.$emit('change', res);
    },
  },
};
</script>

<style lang="scss" scoped>
.checkboxes-related {
  $block: &;

  &__columns {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    list-style: none;
    margin: 0;
    padding: 0;

    #{$block}.is-mobile & {
      display: block;
    }
  }

  &__column {
    min-width: 0;
    margin-right: 10px;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    min-height: 250px;

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
